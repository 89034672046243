import './AboutThing.css';

function AboutThing() {
    return  (
        <div className="about-thing">
            <a>abouted me</a>
        </div>
    )
}

export default AboutThing;