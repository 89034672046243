import './VertSideBar.css';
import { debounce } from '../../util/FuncUtils';


var lastCall = 0;
const toggleMenu = (state?: boolean) => {
    if (Date.now() - lastCall < 250) return;
    lastCall = Date.now();

    const elem = document.getElementsByClassName('vert-side-bar')[0];
    const active = elem.classList.contains('vert-side-bar-active');
    state = state ?? !active;
    if (active === state) return;

    setTimeout(() => {
        elem.classList.toggle('vert-side-bar-active');
    }, 250)

    elem.animate([
        {transform: active ? 'translateX(0%)' : 'translateX(100%)'},
        {transform: active ? 'translateX(100%)' : 'translateX(0%)'}
    ], {
        duration: 250,
        fill: 'forwards'
    });
};

const onClick = () => toggleMenu();


function Option(props: {text: string, href: string}): JSX.Element {
    return (
        <li className='vert-option'>
            <a className='vert-option-content vert-hover-effect' href={props.href} onClick={onClick}>{props.text}</a>
        </li>
    )
}

function VertSideBar(props: {props: {vertMenuOnClick: () => void}}): JSX.Element {
    props.props.vertMenuOnClick = onClick

    return (
        <div className='vert-side-bar' style={{transform: 'translateX(100%)'}}>
            <div className='vert-side-bar-display'>
                <ul className='vert-items'>
                    <Option text='Home' href='#home' />
                    <Option text='About' href='#about' />
                    <Option text='Projects' href='#projects' />
                    <Option text='Contact' href='#contact' />
                </ul>
            </div>

            <div style={{
                backgroundColor: "transparent",
                height: "150vh", width: "100%",
                position: "absolute", marginTop: "-50%",
                left: "0", zIndex: "19",
                pointerEvents: "all", cursor: "none"
                }} onClick={() => toggleMenu(false)} />
        </div>
    )
}

export default VertSideBar;