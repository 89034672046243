import './HomeSection.css';

function HomeSection() {
    return (
      <div className='home-content'>
          <div className='home-display'>
            <div className='sub-container'>
              <div className='head-container bounce-in-after'>
                <h1>Dylan Bruner</h1>
                <a className='short-description'>17yo, HS Student, Developer.</a>
              </div>
              <div className='description-container bounce-in'>
                <p>Here is a really great description, eventually I should probably actually write something here? Or maybe not I'm not sure!</p>
              </div>
            </div>
          </div>
      </div>
    )
}


export default HomeSection;