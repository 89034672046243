

function Section(props: {id: string, active?: boolean, children: JSX.Element}) {
    return (
        <div className={"about-section" + (props.active ? ' active' : '')} id={props.id} style={{display: "none", width: "100%", height: "100%"}}>
            {props.children}
        </div>
    )
}

export default Section;