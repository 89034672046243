import React from 'react';
import './ProjectList.css';

function ProjectList(props: any) {
  return (
    <div className="project-list">
      {props.children}
    </div>
  );
}

export default ProjectList;