import './SkillBar.css';

function SkillBar(props: {name: string, level: number}) {
    return (
        <div className='skill-bar'>
            <div className='info-display'>
                <div className='skill-name'>{props.name}</div>
                <div className='percentage'>{props.level}%</div>
            </div>
            <div className='progress-bar'>
                <div className='progress' style={{width: `${props.level}%`}}></div>
            </div>
        </div>
    )
}

export default SkillBar;