import './Footer.css';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

function Footer() {
    return (
        <div className='footer'>
            <div className='middle-box'>
                <a>Made with React; hosted with GitHub Pages.</a>
                <a href='https://www.github.com/DylanBruner/' target='_blank' className='view-code'>
                    <a>[Code]</a>
                </a>
            </div>
        </div>
    )
}

export default Footer;