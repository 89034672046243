import './NavBar.css';
import MenuIcon from '@mui/icons-material/Menu';
import VertSideBar from './VertSideBar';


function NavBar() {
    window.addEventListener('load', () => {
        const anchors = Array.from(document.getElementsByClassName('section-anchor'));

        document.addEventListener('scroll', () => {
            anchors.forEach((anchor) => {
                const rect = anchor.getBoundingClientRect();
                if (rect.top > 0 && rect.top < 150){
                    const location = window.location;
                    if (location.hash === `#${anchor.id}`) return;
                    window.history.replaceState(null, '', `#${anchor.id}`);
                    window.dispatchEvent(new Event('hashchange'));
                }
            });
        });

        window.addEventListener('hashchange', (e) => {updateHash()});
        updateHash();
    });


    function updateHash(){
        const location = window.location.hash;

        document.querySelectorAll('.active-option').forEach((element) => {
            element.classList.remove('active-option');
        });

        document.querySelectorAll(`a[href="${location}"]`).forEach((element) => {
            element.classList.add('active-option');
        });

        document.getElementsByClassName('nav-name-text')[0].classList.remove('active-option');
    }

    function MenuOption(props: {href: string, text: string}): JSX.Element {
        return (
            <li className='option'>
                <a className='option-content hover-effect' href={props.href}>{props.text}</a>
            </li>
        )
    }

    var props = {
        "vertMenuOnClick": () => {console.log('clicked')}
    }

    return (
        <nav className="nav-bar">
            <ul className='horizontal-nav-bar'>
                <MenuOption href="#contact" text="Contact" />
                <MenuOption href="#projects" text="Projects" />
                <MenuOption href="#about" text="About" />
                <MenuOption href="#home" text="Home" />
                
                <li className='vert-menu-option' style={{ float: 'left' }}><a className='option-content hover-effect nav-name-text' href="#home">Dylan Bruner</a></li>
                <li className='vert-menu-option' style={{ float: 'left' }}>
                    <a className='option-content'>
                        <a style={{marginLeft: "-10px"}}>|</a>
                        <a 
                            style={{marginLeft: "5px", color: "white", textDecoration: "none"}}
                            className='hover-effect'
                            href="https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf"
                            target='_blank'
                        >Resume</a>
                    </a>
                </li>

                <li className='vert-menu-option'><a className='vert-menu-button' onClick={() => props.vertMenuOnClick()}><MenuIcon /></a></li>
            </ul>

            <VertSideBar props={props} />
        </nav>
    )
}

export default NavBar;