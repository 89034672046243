import './Home.css';
import NavBar from '../../components/nav-bar/NavBar';
import HomeSection from './sections/home/HomeSection';
import ProjectsSection from './sections/projects/ProjectsSection';
import AboutSection from './sections/about/AboutSection';
import ContactSection from './sections/contact/ContactSection';
import Footer from './footer/Footer';

// https://alexandros.tech/
// https://www.hackerrank.com/

/*
button at the bottom to scroll to top, kinda just cause
redo the auto-hash updating thing, (hover based is bad)
clicking some things on (true*) mobile shows the annoying blue outline
figure out analytics at some point, preferably just load some image off a remote server that can log the request,
 - this isn't very transparent though so maybe figure out google analytics or something
fix centering on about view selector for smaller screens
fix color-leakage with the project cards
display the active section in the vert-navbar also 
scroll animation
maybe switch the cards over to a carousel
*/


// var lastScrollTime = 0;
// document.addEventListener('scroll', () => {
//   lastScrollTime = Date.now();
// });

function Section(props: {title: string, children: JSX.Element, className?: string}): JSX.Element {

  function onFocus(element: HTMLElement) {
    // if (Date.now() - lastScrollTime < 100) return;

    // const hash = `#${element.id}`;
    // if (window.location.hash !== hash) {
    //   window.history.pushState(null, '', hash); // Update the hash without triggering a scroll

    //   // send a artificial hashchange event for any code we have that listens for it
    //   const event = new Event('hashchange');
    //   window.dispatchEvent(event);
    // }
  }

  return (
    <div className={'section' + (props.className ? ' ' + props.className : '')} onMouseOver={() => onFocus(document.getElementById(props.title)!)}>
      <a className='section-anchor' id={props.title} style={{display:"block",position:"relative",visibility:"hidden"}}></a>
      {props.children}
    </div>
  )
}

function App() {
  return (
    <div className="App">
        <NavBar />

        <div className='main-body'>
          <Section title="home">
            <HomeSection />
          </Section>

          <Section title="about">
            <AboutSection />
          </Section>

          <Section title="projects">
            <ProjectsSection />
          </Section>

          <Section title="contact" className='contact-section'>
            <ContactSection />
          </Section>


          <Footer />
        </div>

    </div>
  );
}

export default App;