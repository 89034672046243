import './ContactSection.css';
import GitHubIcon from '@mui/icons-material/GitHub';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faHackerrank } from '@fortawesome/free-brands-svg-icons';

function ContactIcon(props: {icon: JSX.Element, link: string}) {
    return (
        <a href={props.link} target="_blank" rel="noreferrer">
            <div className='contact-item'>
                {props.icon}
            </div>
        </a>
    )
}

function ContactSection() {
    return (
        <div className='contact-content'>
            <h1 id='contact-header-text'>CONTACT ME</h1>

            <div className='info-box-container'>
                <div className='info-box'>
                    <LocationOnOutlinedIcon />
                    <a>Michigan, United States</a>
                </div>
                <div className='info-box'>
                    <EmailOutlinedIcon />
                    <a href="mailto:email@gmail.com">dylan@imdylan.dev</a>
                </div>
            </div>

            <div className='contacts'>
                <ContactIcon icon={<GitHubIcon />} link="https://www.github.com/DylanBruner" />
                <ContactIcon icon={<div></div>} link=""/>
                <ContactIcon icon={<FontAwesomeIcon icon={faHackerrank} />} link="https://www.hackerrank.com/profile/brunerdylan5" />
            </div>
        </div>
    )
}

export default ContactSection;