import './EducationThing.css';

function EducationThing() {
    return (
        <div className='education-thing'>
            <a>educated sections</a>
        </div>
    )
}

export default EducationThing;