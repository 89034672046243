import './AboutSection.css';
import Section from './things/Section';
import AboutThing from './things/about-thing/AboutThing';
import EducationThing from './things/education-thing/EducationThing';
import SkillsThing from './things/skills-thing/SkillsThing';

function AboutSection() {
    // Handle animation for clicking on the view selector buttons
    function onClick(viewOption: number) {
      const prevSelectedIndex = Array.from(document.getElementsByClassName('view-option selected')).map((element) => {
        return parseInt(Array.from(element.classList).filter((className) => className.startsWith('view-option-'))[0].split('-')[2]);
      })[0];

      Array.from(document.getElementsByClassName('view-option')).forEach((element) => {
        element.classList.remove('selected');
      });

      const selected = document.getElementsByClassName('view-option-' + viewOption)[0];

      selected.animate([
        {transform: `translateX(${(prevSelectedIndex - viewOption) * 100}%)`},
        {transform: `translateX(0%)`}
      ], {
        duration: 300,
        easing: 'ease-in-out'
      });

      selected.classList.add('selected');

      // Switch the actual view
      document.getElementsByClassName('thing-display')[0]
        .getElementsByClassName('active')[0]
        .classList.remove('active');

      // animate the view and make it active (visible)
      const view = document.getElementById('view-option-' + viewOption);
      view!.animate([
        {transform: `translateX(${(prevSelectedIndex - viewOption) * 100}%)`},
        {transform: `translateX(0%)`}
      ], {
        duration: 300,
        easing: 'ease-in-out'
      });
      view!.classList.add('active');
    }

    return (
      <div className='about-content'>
        <h1 id='about-header-text'>ABOUT</h1>

        <div className='content-container'>
          <div className='view-selector-container'>
            <ul className='view-selector-text'>
              <li>ABOUT</li>
              <li>SKILLS</li>
              <li>EDUCATION</li>
            </ul>

            <ul className='view-selector'>
              <li className='view-option view-option-1 selected' onClick={() => onClick(1)}></li>
              <li className='view-option view-option-2' onClick={() => onClick(2)}></li>
              <li className='view-option view-option-3' onClick={() => onClick(3)}></li>
            </ul>
          </div>

          <div className='thing-display'>
            {/* <AboutThing id='view-option-1'/>
            <SkillsThing id='view-option-2'/>
            <EducationThing id='view-option-3'/> */}
            <Section id='view-option-1' active={true}>
              <AboutThing />
            </Section>
            <Section id='view-option-2'>
              <SkillsThing />
            </Section>
            <Section id='view-option-3'>
              <EducationThing />
            </Section>
          </div>
        </div>
      </div>
    )
}

export default AboutSection;