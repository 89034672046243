import './ProjectCard.css';
import { debounce } from '../../util/FuncUtils';

interface ProjectCardProps {
    url: string;
    title: string;
    description: string;
}

var projectCardCount = 0;

export default function ProjectCard({ url, title, description }: ProjectCardProps) {
    const myCount = projectCardCount++;

    const getInner = () => {return document.getElementsByClassName(`project-card-${myCount}`)[0].getElementsByClassName('card-inner')[0];}

    const onClick = debounce(() => {
        const inner = getInner();
        const flipped = inner?.classList?.contains('is-flipped');
        inner?.classList?.toggle('is-flipped');

        if (flipped) inner?.classList?.remove('hover-grow');

        inner.animate([
            {transform: `rotateY(${flipped ? -180 : 0}deg)`},
            {transform: `rotateY(${flipped ? 0 : -180}deg)`}
        ], {
            duration: 500,
            easing: 'ease-in-out',
            fill: 'forwards'
        });
    }, 500);


    return (
        <div className={`project-card project-card-${myCount}`} onClick={onClick}>
            <div className='card-inner'>
                <div className='card-front'>
                    <img src={url}></img>
                </div>
                <div className='card-back'>
                    <div className='back-content'>
                        <div className='text-content'>
                            <a className='card-title'>{title}</a>
                            <div className='card-description-wrapper'>
                                <span className='card-description'>{description}</span>
                            </div>
                        </div>

                        <a className='footer-wrapper' href='https://www.github.com/DylanBruner' target='_blank'>
                            <div className='card-footer' onClick={(e) => {e.stopPropagation()}}>
                                <a>See code</a>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}