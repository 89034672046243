import SkillBar from '../../../../../../components/skill-bar/SkillBar';
import './SkillsThing.css';

function SkillsThing() {
    return (
        <div className='skills-thing'>
            <div className='side side-1'>
                <SkillBar name='React' level={90} />
                <SkillBar name='React' level={90} />
                <SkillBar name='React' level={90} />
                <SkillBar name='React' level={90} />
                <SkillBar name='React' level={90} />
                <SkillBar name='React' level={90} />
                <SkillBar name='React' level={90} />
            </div>

            <div className='side side-2'>
                <SkillBar name='React' level={90} />
                <SkillBar name='React' level={90} />
                <SkillBar name='React' level={90} />
                <SkillBar name='React' level={90} />
                <SkillBar name='React' level={90} />
                <SkillBar name='React' level={90} />
                <SkillBar name='React' level={90} />
            </div>
        </div>
    )
}

export default SkillsThing;